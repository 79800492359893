* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: white;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}
.bg {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  overflow: auto;
  background: linear-gradient(to right, #3a7bd5, #00d2ff);
}
.center {
  width: 400px;
  background: white;
  border-radius: 3px;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 50px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.center h1 {
  text-align: center;
  padding: 10px 0;
  color: rgb(27, 18, 18);
  font-size: 40px;
  font-weight: bold;
}

.center form {
  padding: 0 40px;
  box-sizing: border-box;
}

form .txt_field {
  position: relative;
  border: 2px solid white;
  border-radius: 5px;
  margin: 30px 0;
  box-shadow: 1px 1px 15px rgb(211, 194, 194);
}
.txt_field input {
  width: 100%;
  height: 40px;
  padding-left: 45px;
  padding-right: 50px;

  font-size: 17px;
  border: none;
  background: none;
  outline: none;
}
.txt_field label {
  position: absolute;
  top: 25%;
  left: 10px;
  color: #adadad;
  font-size: 16px;
  margin-left: 30px;
}

.center button {
  margin-left: 0px;
  width: 70%;
  height: 40px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 14px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 30px;
  margin-left: 15%;
  background: #3a7bd5;
}

button:hover {
  transition: 0.25s;
  background: #0baff0;
}
#chk {
  color: #9633ff;
  margin: 0px 10px;
}

.signup_link {
  text-align: center;
  font-size: 14px;
  color: #adadad;
  margin: 20px 0;
}
.signup_link a {
  text-decoration: none;
  color: gray;
}
.cpr {
  text-align: center;
  font-size: 13px;
  color: #adadad;
}
.cpr a {
  text-decoration: none;
  color: gray;
}

.blck p {
  text-align: right;
  margin-right: 10px;
}

.eye {
  position: absolute;
  color: #3a7bd5;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
