* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.backgr {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    #3a7bd5,
    #00d2ff
  ); 
}

.container-1 {

 width: 400px;
 background: white;
 border-radius: 3px;
 overflow: hidden;
 padding: 20px;
 margin-bottom: 50px;
 box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.container-1 h1 {
  text-align: center;
  padding: 10px 0;
  color: rgb(27, 18, 18);
  font-size: 40px;
  font-weight: bold;
}

.container-1 form {
  padding: 0 40px;
  box-sizing: border-box;
}

form .txt {
  position: relative;
  border: 2px solid white;
  border-radius: 5px;
  margin: 30px 0;
  box-shadow: 1px 1px 15px rgb(211, 194, 194);
}

.txt input {
  width: 100%;
  height: 40px;
  padding-left: 45px;
  padding-right: 50px;
  font-size: 17px;
  border: none;
  background: none;
  outline: none;
}

.txt select {
  width: 100%;
  height: 40px;
  padding-left: 45px;
  padding-right: 50px;
  font-size: 17px;
  border: none;
  background: none;
  outline: none;
  margin-left: -20px;
}

.loginbut button{
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 18px;
  color: #e9f4fb;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin: auto;
  background: #3a7bd5; 
}

.loginbut button:hover {
  transition: 0.25s;
  background: #0baff0; 
}




