.floatingContainerPieChart {
  font-size: 14px;
  background-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 3px;
  width: auto;
  height: auto;
  margin: auto;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  opacity:1;
  transition: opacity 0.5s linear;
}

.floatingContainer {
  font-size: 14px;
  background-color: hsla(0, 0%, 100%, 0.9);
  border-radius: 3px;
  padding: 5px 0 5px 0;
  width: auto;
  height: auto;
  margin: auto;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  opacity:1;
  transition: opacity 0.5s linear;
}