.menu {
  font-size: 15px;
  font-weight: bold;
  background-color: #fff;
  padding: 0px 0 0px 0;
  width: 150px;
  height: auto;
  margin: 0;
  position: absolute;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  list-style: none;
  opacity: 1;
}

.menuBtn {
  width: 100%;
  border-radius: 0%;
  background-color: #2b3033;
  padding: 0.5em 0.5em 0.5em;
  color: #fff;
  font-size: 14;
}

.menuBtn:hover{
  background-color: #8b8b8b;
}
