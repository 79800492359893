.deleteProjectBtn{
    background-color: rgb(255, 255, 255);
    padding: 5px;
    padding: 0 15px;
    border-radius:30%;
    border: 1px rgba(0, 0, 0, 0.7)
}

.deleteProjectBtn:hover{
    background-color: tomato;
    transition: 0.2s linear;
    color: rgb(255, 255, 255);
}